import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
import { Text, Container, Link } from 'theme-ui';
import Update from '../../components/Update';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Update date="December 2019" mdxType="Update">
  In the year since I've wrote this, it's become increasingly clear that
  this is not a good method for building accordions. Beyond all the work it
  takes to make the thing functional (you know, the point of this post),{' '}
  <Link href="https://daverupert.com/2019/12/why-details-is-not-an-accordion/" mdxType="Link">
    Dave Rupert recently wrote a short post detailing the accessibility
    shortcomings of this approach
  </Link>
  . I share Dave's opinion that the web, as a platform, needs to bring some
  of these common controls to the table to ensure a consistent, accessible experience
  for all of its users.
    </Update>
    <p>{`There are few UI components encountered as frequently as the accordion. Even if you’re unfamiliar with the term, you’re familiar with the pattern: a string of text, sometimes accompanied by a button or icon, that reveals more content underneath when clicked. Thousands of developers and hundreds of UI frameworks have created accordions, often with their own rigid markup structures and (potentially bloated) JavaScript. But did you know that a perfectly functional accordion component ships with `}<a parentName="p" {...{
        "href": "https://caniuse.com/#feat=details"
      }}>{`most modern browsers`}</a>{`? Check this out:`}</p>
    {
      /*
       The weird formatting here is to prevent the markdown parser from turning it
       into a code block
      */
    }
    <Container sx={{
      maxWidth: 'mdx-measure'
    }} mdxType="Container">
      <details>
        <summary>This is my cool accordion</summary>
        <Text as="p" my={3} mdxType="Text">
  100% HTML, baby. #usetheplatform
        </Text>
      </details>
    </Container>
    <p>{`That’s all HTML! The code looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<details>
  <summary>This is my cool accordion</summary>

  <p>100% HTML, baby. #usetheplatform</p>
</details>
`}</code></pre>
    <p>{`The element that helps us achieve this marvel of modern engineering is called `}<a parentName="p" {...{
        "href": "http://developer.mozilla.org/en-US/docs/Web/HTML/Element/details"
      }}><inlineCode parentName="a">{`details`}</inlineCode></a>{` (along with `}<inlineCode parentName="p">{`summary`}</inlineCode>{` to set custom titles). It allows us to create content that is not visible until the element is clicked on. Sounds like an accordion to me!`}</p>
    <p>{`Of course, there are some downsides to the `}<inlineCode parentName="p">{`details`}</inlineCode>{` element. For starters, it isn’t exactly what I’d call attractive. There’s no way to animate content in and out, and no way to adjust the “twistie” (the triangle icon that indicates the component state). In addition, working in `}<em parentName="p">{`most`}</em>{` modern browsers means that it doesn’t work in `}<em parentName="p">{`all`}</em>{` modern browsers, not to mention legacy browsers. But, much like our UI framework-developing forebears, we can use some CSS & JavaScript to solve those issues, with the added benefit of an accordion that works when JavaScript (and even CSS!) is disabled.`}</p>
    <h2>{`The Beauty Is in the Details`}</h2>
    <p>{`The first and easiest changes we should make just involve making the element display consistently in all browsers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`details {
  display: block;
}
summary {
  display: list-item;
}
`}</code></pre>
    <p>{`Similarly easy, though a little more opinionated, is changing the cursor to highlight that the element is interactive.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`summary {
  cursor: pointer;
}
`}</code></pre>
    <p>{`Now let’s get into the fun stuff. First up is changing the default twistie to be something closer to convention.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* Hide the default twistie */
/* Spec-compliant: */
summary {
  list-style-type: none;
}
/* Non-standard: */
summary::-webkit-details-marker {
  display: none;
}

/* Display a more common one */
summary {
  position: relative;
  padding-right: 1.5rem;
}
summary::after {
  content: '+';
  position: absolute;
  top: calc(50% - 0.5em);
  right: 0;
}
details[open] summary::after {
  content: '-';
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/lowmess/pen/zMjagE"
      }}>{`Here’s the accordion`}</a>{` as it looks at this point:`}</p>
    <Container sx={{
      iframe: {
        border: 1,
        borderColor: 'muted'
      }
    }} mdxType="Container">
  <iframe height="300" scrolling="no" title="Details Accordion (CSS Resets Only)" src="//codepen.io/lowmess/embed/zMjagE/?height=300&theme-id=22727&default-tab=result" frameBorder="no" allowtransparency="true" allowFullScreen="true" style={{
        "width": "100%"
      }}>
    See the Pen{' '}
    <a href="https://codepen.io/lowmess/pen/zMjagE/">
      Details Accordion (CSS Resets Only)
    </a>{' '}
    by Alec Lomas (<a href="https://codepen.io/lowmess">@lowmess</a>) on{' '}
    <a href="https://codepen.io">CodePen</a>.
  </iframe>
    </Container>
    <p>{`Not bad for a few lines of CSS! There’s a few more changes I would make before calling this anything approaching good-looking, though.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`details {
  border-bottom: 1px solid #eee;
  padding: 1rem;
}
:not(details) + details,
details:first-of-type {
  border-top: 1px solid #eee;
}

summary {
  margin-bottom: 0;
  font-weight: 700;
}
summary + * {
  margin-top: 1rem;
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/lowmess/pen/eQrjBG"
      }}>{`Now we’ve got a relatively attractive accordion component`}</a>{`. We could stop now, and we would have a working component that, with a few tweaks, could be dropped onto almost any site and Just Work™️.`}</p>
    <Container sx={{
      iframe: {
        border: 1,
        borderColor: 'muted'
      }
    }} mdxType="Container">
  <iframe height="300" scrolling="no" title="Details Accordion (Base Styles)" src="//codepen.io/lowmess/embed/eQrjBG/?height=300&theme-id=22727&default-tab=result" frameBorder="no" allowtransparency="true" allowFullScreen="true" style={{
        "width": "100%"
      }}>
    See the Pen{' '}
    <a href="https://codepen.io/lowmess/pen/eQrjBG/">
      Details Accordion (Base Styles)
    </a>{' '}
    by Alec Lomas (<a href="https://codepen.io/lowmess">@lowmess</a>) on{' '}
    <a href="https://codepen.io">CodePen</a>.
  </iframe>
    </Container>
    <h2>{`Enchanting Progressive Enhancement`}</h2>
    <p>{`We don’t want to be outdone by those UI frameworks of yore, though. So we’ll need to do a little more work to animate the content in and out. The base markup does have to be changed to be a little less clean, and we’ll need some new CSS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<details>
  <summary>An updated details accordion</summary>

  <div class="content">
    <p>I'm some content!</p>
  </div>
</details>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.content {
  overflow-y: hidden;
  transition: all 0.4s ease;
}

.content.is-closed {
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
`}</code></pre>
    <p>{`You may have noticed that we didn’t apply the `}<inlineCode parentName="p">{`is-closed`}</inlineCode>{` class to the content in the markup. Since we’re using JS to trigger that class, and it hides the content, we only want to apply the class if JS is allowed to run on the page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`for (const content of document.querySelectorAll('details .content')) {
  content.classList.add('is-closed')
}
`}</code></pre>
    <p>{`There’s one last piece we need to set into place before we can trigger the animation. When the element is closed, it has an applied `}<inlineCode parentName="p">{`max-height`}</inlineCode>{` of `}<inlineCode parentName="p">{`0`}</inlineCode>{`. Since the `}<inlineCode parentName="p">{`auto`}</inlineCode>{` declaration is not animatable, we’ll need to apply a defined `}<inlineCode parentName="p">{`max-height`}</inlineCode>{` to the content when it’s open. For the smoothest possible animation, that `}<inlineCode parentName="p">{`max-height`}</inlineCode>{` should be the same size as the content height. We can write a simple function to calculate this height and store it as an attribute on the content element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const getContentHeight = (node) => {
  // Force node to display properly
  node.classList.remove('is-closed')
  // Calculate height and store it
  node.setAttribute(
    'data-height',
    \`\${node.getBoundingClientRect().height}px\`
  )
  // Reset node to initial state
  node.classList.add('is-closed')
}
`}</code></pre>
    <p>{`Now that we’ve accounted for that wrinkle, we can handle interaction on the element. In a bit of premature optimization, we’ll `}<a parentName="p" {...{
        "href": "https://javascript.info/event-delegation"
      }}>{`delegate the event`}</a>{` to the document.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`document.addEventListener('click', (event) => {
  if (!event.target.closest('summary')) {
    return
  }

  event.preventDefault()
})
`}</code></pre>
    <p>{`All we’ve told the browser at this point is “if the user has clicked a `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` element, don’t do anything”. The default behavior associated with clicking a `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` element is to set/remove the `}<inlineCode parentName="p">{`open`}</inlineCode>{` attribute on its parent `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` element (the browser then knows to hide/show the content inside of `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` based on this attribute). The browser won’t wait for the content to animate out before hiding it, so we need to do that manually inside our handler. The code to do this is fairly straightforward, and boils down to toggling attributes and classes in a specific order at a specified time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Inside of our event handler
const accordion = event.target.closest('details')
const content = accordion.querySelector('.content')

// Handle closing
if (accordion.hasAttribute('open')) {
  // Animate content out
  content.style.removeProperty('max-height')
  content.classList.add('is-closed')
  // Wait for animation to finish, then remove the \`open\` attribute
  window.setTimeout(() => {
    accordion.removeAttribute('open')
  }, 400)
  // Exit handler
  return
}

// Handle opening
// Set the \`open\` attribute so the content will display
accordion.setAttribute('open', '')
// If our content does not have a calculated height, calculate it
if (!content.hasAttribute('data-height')) {
  getContentHeight(content)
}
// Wait a beat for the height to calculate, then animate content in
window.setTimeout(() => {
  content.style.maxHeight = content.getAttribute('data-height')
  content.classList.remove('is-closed')
}, 0)
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://codepen.io/lowmess/pen/yQjRXy"
      }}>{`Putting it all together`}</a>{` gives us a fully functional, animated accordion component. And because we started with an HTML element that gives us our core functionality by default, a user doesn’t need to have JavaScript running to access the hidden content.`}</p>
    <Container sx={{
      iframe: {
        border: 1,
        borderColor: 'muted'
      }
    }} mdxType="Container">
  <iframe height="300" scrolling="no" title="<details> Accordion (Final Form)" src="//codepen.io/lowmess/embed/yQjRXy/?height=300&theme-id=22727&default-tab=result" frameBorder="no" allowtransparency="true" allowFullScreen="true" style={{
        "width": "100%"
      }}>
    See the Pen{' '}
    <a href="https://codepen.io/lowmess/pen/yQjRXy/">
      &lt;details&rt; Accordion (Final Form)
    </a>{' '}
    by Alec Lomas (<a href="https://codepen.io/lowmess">@lowmess</a>) on{' '}
    <a href="https://codepen.io">CodePen</a>.
  </iframe>
    </Container>
    <h2>{`Extra Credit`}</h2>
    <p>{`Although the accordion we’ve created is great, we’ve only covered the baseline functionality. There are a few ways we can change or improve the functionality further, including but not limited to:`}</p>
    <h3>{`Improve Accessibility`}</h3>
    <p>{`Because the `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` element is baked-in to the platform, it should be accessible by default. However, this only applies to browsers that actually support the element. If you have users from browsers that don’t support the element, considerations for accessibility should be taken (adding `}<inlineCode parentName="p">{`tabindex`}</inlineCode>{` and `}<inlineCode parentName="p">{`aria-`}</inlineCode>{` attributes, for example).`}</p>
    <h3>{`Handle Window Resizing`}</h3>
    <p>{`The vertical expansion animation we’re using to transition accordion content in and out of view is smooth and natural, but there is one issue: it isn’t responsive. Because we only calculate the height of the element once, if the element width changes to the degree that the content flows to a new line, the animation will break. An ideal solution would account for this, and there are a variety of ways we could do so.`}</p>
    <h3>{`Increase Interaction Target Size`}</h3>
    <p>{`We set container padding on the `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` element to give the contents of the accordion room to breath. However, this means to open the accordion you have to click or tap on the `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` content. Setting the padding on the `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{` and content container independently should make the accordion a little easier to use.`}</p>
    <h3>{`Animate the Twistie`}</h3>
    <p>{`Animating the twistie to compliment the content animation would bring another level of polish to our accordion. Extra extra credit: make the animation happen on interaction (instead of relying on the `}<inlineCode parentName="p">{`[open]`}</inlineCode>{` attribute).`}</p>
    <h3>{`Force Content to Show In Non-Screen Environments`}</h3>
    <p>{`Accordions can bring a lot to the table in screen-based environments, however they can also make content inaccessible in non-screen environments (such as when printing or using a screenreader). The content should be accessible in all environments.`}</p>
    <h3>{`Only Open One Item At a Time`}</h3>
    <p>{`Just kidding. If `}<a parentName="p" {...{
        "href": "https://www.nngroup.com/articles/accordions-complex-content/"
      }}>{`you need an accordion at all`}</a>{`, you probably `}<a parentName="p" {...{
        "href": "https://www.smashingmagazine.com/2017/06/designing-perfect-accordion-checklist/#designing-interaction-for-the-accordion"
      }}>{`should not do this`}</a>{`. As it is an action not directly requested by the user, it can cause frustration.`}</p>
    <h3>{`Package the Accordion Into a Reusable Component`}</h3>
    <p>{`We should probably abstract away all of this markup to ensure consistency and improve DX. Frameworks like React and Vue provide a simple way to do this, but it should also be possible in most templating languages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      